<template>
  <v-card>
    <v-dialog persistent max-width="450" v-model="deleteDialog">
      <v-card>
        <v-card-title class="headline primary white--text">
          {{ $t('confirmation') }}
        </v-card-title>

        <v-card-text class="pt-2">
          <v-row>
            <v-col cols="12">
              {{ $t('page_users_edit_confirmation_message') }}
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            :disabled="loading"
            color="red"
            text
            @click="deleteDialog = false"
          >
            {{ $t('cancel') }}
          </v-btn>
          <v-btn
            :loading="loading"
            color="primary"
            text
            @click="confirmDelete()"
          >
            {{ $t('confirm') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-card-title>
      <v-row>
        <v-col cols="auto">
          <v-btn class="label" text color="transparent">
            <v-icon color="secondary" left size="20">$edit</v-icon>
            <div class="font-weight-regular secondary--text text-capitalize">
              {{ $t('page_users_edit_title') }}
            </div>
          </v-btn>
        </v-col>

        <v-spacer></v-spacer>

        <v-col cols="auto">
          <v-btn
            small
            class="text-capitalize font-weight-regular"
            text
            @click="$router.push({ name: 'UserList' })"
          >
            <v-icon color="secondary" left>$goBack</v-icon>
            {{ $t('go_back') }}
          </v-btn>
        </v-col>
      </v-row>
    </v-card-title>

    <v-divider></v-divider>

    <v-card-text>
      <v-container>
        <validation-observer ref="observer">
          <form @submit.prevent="submit">
            <v-row>
              <v-col cols="12" md="4">
                <v-card class="business-info-card">
                  <v-card-text>
                    <div class="primary--text mb-2">
                      {{ $t('business_info') }}
                    </div>
                    <div class="mb-2">
                      <div>
                        {{ singleUserCompany.id }} /
                        {{ singleUserCompany.display_name }}
                      </div>
                      <div>
                        {{ $t('business_type') }}:
                        {{
                          singleUserCompany.type !== null
                            ? singleUserCompany.type.name
                            : ''
                        }}
                      </div>
                      <div>
                        {{ singleUserCompany.postcode }}
                        {{
                          singleUserCompany.prefecture !== null
                            ? singleUserCompany.prefecture.name
                            : ''
                        }}
                        {{ singleUserCompany.city }}
                        {{ singleUserCompany.address }}
                      </div>
                      <div>{{ $t('tel') }}: {{ singleUserCompany.phone }}</div>
                    </div>
                    <div class="mb-2">
                      <div>
                        {{ $t('number_of_employee') }}:
                        {{
                          singleUserCompany.head_count !== null
                            ? singleUserCompany.head_count.name
                            : ''
                        }}
                      </div>
                      <div>
                        {{ $t('registration_date') }} :
                        {{ singleUserCompany.registration_date }}
                      </div>
                      <div>
                        {{ $t('registered') }} :
                        {{ singleUserCompany.registration_date }}
                      </div>
                    </div>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col offset="2" cols="12" md="6">
                <v-row>
                  <v-col cols="12">
                    <v-row align="center">
                      <v-col cols="6">
                        <v-row>
                          <v-col cols="4" md="4" class="text-right">
                            <span class="label-text">{{ $t('id') }}</span>
                          </v-col>
                          <v-col cols="8">
                            <span class="primary--text">{{
                              singleUser.id
                            }}</span>
                          </v-col>
                        </v-row>
                      </v-col>

                      <v-col cols="6">
                        <v-row align="center">
                          <v-col cols="2">
                            <v-avatar
                              class="white--text avatar-bg-grey"
                              size="40"
                            >
                              <v-img
                                :src="!preview ? file : preview"
                                width="40"
                              ></v-img>
                            </v-avatar>
                          </v-col>
                          <v-col cols="10">
                            <validation-provider
                              v-slot="{ errors }"
                              name="photo"
                              rules=""
                            >
                              <v-file-input
                                accept="image/*"
                                style="display:none"
                                ref="file"
                                :error-messages="errors"
                                @change="updateFileView"
                              />
                            </validation-provider>
                            <v-btn
                              text
                              small
                              color="primary"
                              class="text-capitalize"
                              @click="$refs.file.$refs.input.click()"
                            >
                              <v-icon left>mdi-plus</v-icon>
                              {{ $t('upload_file') }}
                            </v-btn>
                          </v-col>
                        </v-row>
                      </v-col>

                      <v-col
                        v-for="field in fields"
                        :key="field.name"
                        cols="12"
                      >
                        <v-row align="center">
                          <v-col cols="4" md="2" class="text-right label-text">
                            {{ field.label }}
                          </v-col>
                          <v-col v-if="field.type === 'label'" cols="auto">
                            <span
                              :class="field.color ? field.color + '--text' : ''"
                              >U10234</span
                            >
                          </v-col>

                          <template
                            v-else-if="
                              field.type === 'text' || field.type === 'email'
                            "
                          >
                            <v-col
                              cols="8"
                              :md="field.additional_field ? '4' : '8'"
                            >
                              <validation-provider
                                v-slot="{ errors }"
                                :name="field.name"
                                :rules="field.rules"
                              >
                                <v-text-field
                                  outlined
                                  dense
                                  flat
                                  :type="field.type"
                                  v-model="field.value"
                                  :error-messages="errors"
                                ></v-text-field>
                              </validation-provider>
                            </v-col>
                            <v-col
                              v-if="field.additional_field"
                              cols="8"
                              :md="field.additional_field ? '4' : '8'"
                              offset="4"
                              offset-md="0"
                            >
                              <validation-provider
                                v-slot="{ errors }"
                                :name="field.additional_field.name"
                                :rules="field.additional_field.rules"
                              >
                                <v-text-field
                                  outlined
                                  dense
                                  flat
                                  v-model="field.additional_field.value"
                                  :error-messages="errors"
                                ></v-text-field>
                              </validation-provider>
                            </v-col>
                          </template>

                          <template v-else-if="field.type === 'groups'">
                            <v-col cols="8">
                              <validation-provider
                                v-slot="{ errors }"
                                :name="field.name"
                                :rules="field.rules"
                              >
                                <v-autocomplete
                                  v-model="selectedGroups"
                                  :error-messages="errors"
                                  :items="groups"
                                  item-text="name"
                                  item-value="id"
                                  :menu-props="{ maxHeight: '400' }"
                                  :placeholder="field.placeholder"
                                  multiple
                                  dense
                                  outlined
                                  chips
                                  deletable-chips
                                  small-chips
                                  type="text"
                                ></v-autocomplete>
                              </validation-provider>
                            </v-col>
                          </template>

                          <template v-else-if="field.type === 'link'">
                            <v-col cols="8">
                              <v-btn
                                color="primary"
                                text
                                small
                                class="text-capitalize"
                                @click="sendResetEmail"
                              >
                                {{ field.value }}
                                <v-icon size="12" right>$edit</v-icon>
                              </v-btn>
                            </v-col>
                          </template>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>

              <v-col cols="12" class="text-center">
                <v-row align="center" justify="center">
                  <v-col cols="auto">
                    <v-btn
                      color="error"
                      text
                      :disabled="formStatus"
                      :loading="formStatus"
                      class="text-capitalize font-weight-regular px-2"
                      depressed
                      small
                      min-width="120"
                      @click="deleteUser()"
                    >
                      {{ $t('delete') }}
                    </v-btn>
                  </v-col>
                  <v-col cols="auto">
                    <v-btn
                      type="submit"
                      color="primary"
                      :disabled="formStatus"
                      :loading="formStatus"
                      class="text-capitalize font-weight-regular"
                      depressed
                      small
                      min-width="120"
                    >
                      {{ $t('update') }}
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </form>
        </validation-observer>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
import _ from 'lodash'

export default {
  name: 'index',

  created() {
    this.loading = true

    this.$store.dispatch('GROUP_GET_ALL')
    this.$store
      .dispatch('USER_GET', { id: this.$route.params.id })
      .then(() => {
        this.file = this.singleUser.photo
        this.fillFields()
      })
      .finally(() => (this.loading = false))
  },

  computed: {
    ...mapGetters(['singleUser', 'singleUserCompany', 'groups'])
  },

  data() {
    return {
      loading: false,
      errors: '',
      fields: [
        {
          label: this.$t('page_users_edit_field_name'),
          name: 'last_name',
          type: 'text',
          value: '',
          rules: 'required',
          additional_field: {
            type: 'text',
            name: 'first_name',
            rules: 'required',
            value: ''
          }
        },
        {
          label: this.$t('page_users_edit_field_furigana'),
          name: 'last_name_kana',
          type: 'text',
          value: '',
          rules: 'required',
          additional_field: {
            type: 'text',
            name: 'first_name_kana',
            rules: 'required',
            value: ''
          }
        },
        {
          label: this.$t('page_users_edit_field_position'),
          name: 'position',
          type: 'text',
          rules: '',
          value: ''
        },
        {
          label: this.$t('page_users_edit_field_email'),
          name: 'email',
          type: 'text',
          rules: 'required|email',
          value: ''
        },
        {
          label: this.$t('page_users_edit_field_group'),
          name: 'groups',
          type: 'groups',
          placeholder: this.$t('page_users_edit_field_group_placeholder'),
          value: '',
          rules: ''
        },
        {
          label: this.$t('page_users_edit_field_password'),
          name: 'password',
          type: 'link',
          value: this.$t('send_password_reset_text')
        }
      ],
      selectedGroups: [],
      formStatus: false,
      file: null,
      preview: null,
      deleteDialog: false
    }
  },

  methods: {
    fillFields() {
      let user = this.singleUser

      for (const field of this.fields) {
        if (field.type === 'link') continue
        if (field.additional_field) {
          field.additional_field.value = user[field.additional_field.name]
        }

        field.value = user?.[field.name]
        if (field.type === 'groups' && user.groups && user.groups.length > 0) {
          this.selectedGroups = []
          for (let i = 0; i < user.groups.length; i++) {
            let singleGroup = user.groups[i]
            this.selectedGroups.push(singleGroup.id)
          }
        }
      }
    },

    updateFileView(e) {
      this.preview = URL.createObjectURL(e)
      this.file = e
    },

    submit() {
      this.formStatus = true
      this.$refs.observer.validate().then(success => {
        if (!success) {
          this.formStatus = false
          return
        }

        let data = new FormData()
        if (typeof this.file !== 'string') data.append('photo', this.file)

        this.fields.forEach(field => {
          if (field.name !== 'password') {
            if ({}.hasOwnProperty.call(field, 'additional_field')) {
              data.append(
                field.additional_field.name,
                field.additional_field.value
              )
            }
            if (field.name == 'groups') {
              data.append('groups', [])
            } else if (
              field.name == 'position' &&
              (!field.value || field.value == 'undefined' || field.value == '')
            ) {
              data.append(field.name, '')
            } else {
              data.append(field.name, field.value)
            }
          }
        })

        for (const group of this.selectedGroups) {
          data.append('groups[]', group)
        }

        this.$store
          .dispatch('EDIT_USER', { id: this.$route.params.id, formData: data })
          .then(result => {
            if (result.status === 200) this.$refs.observer.reset()
            this.$router.push({
              name: 'UserList'
            })
          })
          .finally(() => {
            this.formStatus = false
          })
      })
    },

    sendResetEmail() {
      this.formStatus = true
      let data = {}
      data.email = this.singleUser.email
      data.reset_url = process.env.VUE_APP_USER_PASSWORD_RESET_URL

      this.$store.dispatch('USER_PASSWORD_RESET_POST', data).finally(() => {
        this.formStatus = false
      })
    },

    deleteUser() {
      this.deleteDialog = true
    },

    confirmDelete() {
      this.loading = true
      this.$store
        .dispatch('DELETE_USER', {
          id: this.$route.params.id
        })
        .then(() => {
          this.deleteDialog = false
          this.$router.push({ name: 'UserList' })
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>

<style lang="scss" src="./style.scss" scoped></style>
